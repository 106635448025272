import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import BuyNow from 'components/directus/buy-now/BuyNow';
import faqDropdownHook from 'hooks/faq-dropdown/faq-dropdown.hook';
import pageTitleHook from 'hooks/page-title/page-title.hook';
import WindowResizeHook from 'hooks/resize/resize.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { FAQDropDownList } from './faq-dropdown-list/FAQDropDownList';
import { FAQDropDownListItem } from './faq-list-item/FAQDropDownListItem';
import { Props } from './PageTitle.interface';

import {
  pageTitle,
  pageTitle__left,
  pageTitle__heading,
  pageTitle__right,
  pageTitle__dropdownTitle,
  pageTitle__divider,
} from './PageTitle.scss';

export default ({ slug, noUnderline, buyService }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, pageTitleHook());
  const isPackage = node.slug.indexOf('package') > -1;
  const faqDropdownPages = faqDropdownHook();

  const screenSm = WindowResizeHook().width < 1024;

  return (
    <div className={`${pageTitle}`}>
      <div className={pageTitle__left}>
        <div className={pageTitle__heading}>
          <Markdown source={node.page_title} container />
        </div>
      </div>
      <div className={pageTitle__right}>
        {!(buyService && screenSm) && <BuyNow slug={slug} isPackage={isPackage} prices />}
        {node.buttons.indexOf('FAQ Dropdown') > -1 && (
          <FAQDropDownList title="FAQs Quick Navigation">
            <FAQDropDownListItem to="/faqs/">
              <p className={pageTitle__dropdownTitle}>FAQ Homepage</p>
            </FAQDropDownListItem>
            {faqDropdownPages.map(({ node }) => (
              <FAQDropDownListItem key={`faq-dropdown-item-${node.directusId}`} to={node.link}>
                {node.title}
              </FAQDropDownListItem>
            ))}
          </FAQDropDownList>
        )}
      </div>
      <span className={noUnderline ? '' : pageTitle__divider} />
    </div>
  );
};
