import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPackage, PackageEdges } from './packages.interface';

export default (): PackageEdges[] => {
  const { allDirectusPackage } = useStaticQuery<AllDirectusPackage>(graphql`
    query {
      allDirectusPackage {
        edges {
          node {
            directusId
            slug
            name
            type
            short_description
            description
            price {
              value
            }
            discounted_price {
              value
            }
            namecheck_path
            checkout_path
            rich_snippet_image {
              data {
                full_url
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusPackage.edges;
};
