import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusMainBanner, MainBannerEdge } from './main-banner.interface';

export default (): MainBannerEdge[] => {
  const { allDirectusMainBanner } = useStaticQuery<AllDirectusMainBanner>(graphql`
    query {
      allDirectusMainBanner {
        edges {
          node {
            directusId
            slug
            background_colour
            type
            desktop_title
            desktop_image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            desktop_image_alt
            mobile_title
            mobile_image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            mobile_image_alt
          }
        }
      }
    }
  `);

  return allDirectusMainBanner.edges;
};
