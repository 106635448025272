import React from 'react';

import { Link } from 'components/common/link/Link';
import { Props } from './ListitemFAQ.interface';

import { item, item__link } from './ListItemFAQ.scss';

export const FAQDropDownListItem = ({ to, children }: Props): JSX.Element => (
  <li className={item}>
    <Link className={item__link} to={to}>
      {children}
    </Link>
  </li>
);
