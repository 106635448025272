import React from 'react';

import { Props } from './ButtonGroup.interface';

import { btnGrpBlock, btnGrpReverse, btnGrpToolbar, btnGrpColumn, btnGrp } from './ButtonGroup.scss';

export default ({ children, className, block, reverse, toolbar, column }: Props): JSX.Element => {
  const blockClass = block !== undefined ? btnGrpBlock : '';
  const reverseClass = reverse !== undefined ? btnGrpReverse : '';
  const toolbarClass = toolbar !== undefined ? btnGrpToolbar : '';
  const columnClass = column !== undefined ? btnGrpColumn : '';

  return (
    <div className={`${btnGrp} ${className || ''} ${blockClass} ${columnClass} ${reverseClass} ${toolbarClass}`}>
      {children}
    </div>
  );
};
