import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Props } from './FAQDropDownList.interface';

import {
  dropdown__button___open,
  list__open,
  list__closed,
  dropdown,
  dropdown__button,
  dropdown__title,
  list,
} from './FAQDropDownList.scss';

export const FAQDropDownList = ({ children, title }: Props): JSX.Element => {
  const [visible, setIsVisible] = useState<boolean>(false);
  const toggleDropdown = () => setIsVisible(!visible);
  const state = typeof window !== 'undefined' && window.history ? window.history.state : {};

  useEffect((): void => setIsVisible(false), [state]);

  const menu = visible
    ? {
        button: dropdown__button___open,
        listStyle: list__open,
      }
    : {
        button: '',
        listStyle: list__closed,
      };

  return (
    <div className={dropdown}>
      <OutsideClickHandler onOutsideClick={() => setIsVisible(false)}>
        <button onClick={toggleDropdown} className={`${dropdown__button} ${menu.button}`} type="button">
          <h4 className={dropdown__title}>{title}</h4>
        </button>

        <ul className={`${list} ${menu.listStyle}`}>{children}</ul>
      </OutsideClickHandler>
    </div>
  );
};
