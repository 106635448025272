import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Markdown from 'components/common/markdown/Markdown';
import sitemapHook from 'hooks/sitemap/sitemap.hook';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';

import { sitemap__section, sitemap__title, sitemap__links } from './SiteMap.scss';

export default (): JSX.Element => {
  const sitemap = sitemapHook();

  return (
    <div>
      {sitemap.map(({ node }) => (
        <div key={`sitemap-section-${node.directusId}`} className={sitemap__section}>
          <h3 className={sitemap__title}>{node.heading}</h3>
          <Markdown
            source={node.content}
            className={sitemap__links}
            listItemIcon={
              <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faAngleRight')} size="sm" aria-label="check icon." />
            }
          />
        </div>
      ))}
    </div>
  );
};
