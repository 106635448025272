import React, { CSSProperties } from 'react';

import { Props } from './Section.interface';

import { section___home, section, section___grey, section___white } from './Section.scss';

export default ({ className, top, bottom, children, colour, isHome }: Props): JSX.Element => {
  const styles = {
    home: isHome ? section___home : '',
    colour: colour === 'grey' ? section___grey : section___white,
  };
  const style: CSSProperties = {
    marginTop: top !== undefined ? `${top}px` : '',
    marginBottom: bottom !== undefined ? `${bottom}px` : '',
  };

  return (
    <section className={[section, className, styles.colour, styles.home].join(' ')} style={style}>
      {children}
    </section>
  );
};
